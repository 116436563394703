import React from "react"

import Header from "../components/header/header"
import Navigation from "../components/navigation/navigation"
import SocialFooter from "../components/social-footer/social-footer"
import Footer from "../components/footer/footer"
import PageHeader from "../components/page-header/page-header"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default function Home() {
  return (
    <>
      <SEO title={"Bilan de compétences"} keywords={[`bilan de compétences`]} />
      <div className="at-sitecontainer at-sitecontainer--wide">
        <Header />
        <Navigation />
        <PageHeader
          title="Bilan de compétences"
          header="/images/headers/17.jpg"
        />
        <Layout>
          <article>
            <div className="at-page__content">
              <h2 class="at-page__title">
                Accompagnement pour un bilan de compétences
              </h2>
              <div class="at-page__title-divider"></div>
              <p>
                Le bilan de compétences est une excellente façon de réfléchir à
                tes acquis et tes compétences. Au travers de ce travail, tu vas
                apprendre à mieux te définir et te connaître.
              </p>
              <p>
                Les raisons d’entamer une telle démarche sont multiples et
                divers événements peuvent te pousser à vouloir faire le point
                sur ta vie professionnelle. Que ce soit à la suite d’une
                démission ou d’un licenciement, d’un burn-out, bore-out,
                brown-out ou encore d’une réorientation précoce ou mûrement
                réfléchie, le bilan de compétences peut :
              </p>
              <ul>
                <li>T’offrir une meilleure connaissance de toi ;</li>
                <li>Te permettre de changer de carrière sereinement ;</li>
                <li>T’offrir une évolution au sein de ton entreprise ;</li>
                <li>
                  Te positionner auprès d’employeurs en faisant le point sur tes
                  compétences ;
                </li>
                <li>
                  T’inviter à adapter tes outils professionnels et tes
                  soft-skills ;
                </li>
                <li>
                  T’aider à reprendre confiance en toi et te mettre en action ;
                </li>
                <li>Te supporter dans ta recherche d’emploi ;</li>
                <li>T’engager dans un projet de création d’entreprise ;</li>
                <li>Ou encore te permettre de redynamiser ta carrière.</li>
              </ul>
              <p>
                L’idée majeure est de faire l’inventaire des compétences que tu
                as acquises au fil des ans et des qualités que tu possèdes
                naturellement. Cela te permettra de clarifier trois éléments
                essentiels :
              </p>
              <ol>
                <li>Ce que tu sais faire ;</li>
                <li>Ce que tu aimes faire ;</li>
                <li>
                  Ce que tu peux faire avec l’expertise que tu possèdes et les
                  intérêts qui t'animent.
                </li>
              </ol>
              <p>
                Développer ses compétences s’accompagne d’une vraie réflexion
                personnelle et d’un engagement profond. C’est pourquoi un bilan
                de compétences s'évalue sur une durée de plusieurs mois, soit
                plus d'une vingtaine d'heures de travail.
              </p>
              <p>Durant ces nombreuses séances, nous irons :</p>
              <ul>
                <li>
                  identifier ensemble tes compétences et tes aptitudes
                  mobilisables et/ou transférables pour ton futur professionnel
                  ;
                </li>
                <li>dégager des opportunités de carrière ;</li>
                <li>
                  construire un plan d’actions pour mettre en œuvre ton projet
                  professionnel.
                </li>
              </ul>
              <p>
                Chaque rendez-vous donne lieu à un objectif, où tu dois
                effectuer un travail personnel pour aborder l’entretien suivant.
              </p>
              <p>
                Au cours des semaines de discussions qui seront accompagnées
                d’exercices et de livrets à compléter, tu auras un travail
                précis à effectuer afin de rendre ton portrait professionnel le
                plus juste et réaliste possible.
              </p>
              <p>
                Tu l’auras donc compris, c’est un travail long et minutieux. Je
                suis là pour t’accompagner, t’aider à organiser et traiter
                l’information afin de te donner des éléments d’analyse. Le tout
                est rédigé et offert en toute fin de bilan dans une synthèse
                écrite pour te permettre de retrouver toutes tes réflexions. De
                tes aspirations à ton plan d’action, chaque étape de
                concrétisation figure dans ce document, véritable support de tes
                évolutions possibles.
              </p>
              <p>
                Ensemble et au travers d’une approche modulaire conçue par
                l’équipe InTeam de Toulouse en France, nous arriverons à mieux
                structurer tes besoins et atteindre les objectifs professionnels
                que tu t’es fixés.
              </p>
              <p>
                <span role="img" aria-label="info">
                  📍
                </span>{" "}
                <strong>Point info</strong>{" "}
                <span role="img" aria-label="info">
                  📍
                </span>
                <br />
                Français de France : En mobilisant votre Compte Personnel de
                Formation (CPF) ou en le co-finançant avec votre employeur par
                exemple, vous pouvez faire le point sur votre parcours aussi
                bien personnel que professionnel.
              </p>
            </div>
          </article>
        </Layout>
        <SocialFooter />
        <Footer />
      </div>
    </>
  )
}
